<template>
  <div
    ref="inboxContainer"
    class="inbox-container"
    :class="{ 'is-mobile': isMobile }"
  >
    <div class="inbox-wrapper">
      <transition
        name="slide-fade"
        mode="out-in"
      >
        <div
          v-show="!isMobile || (isMobile && !notificationDetail)"
          class="list-wrapper inbox-column"
          :class="{ 'is-mobile': isMobile }"
        >
          <div class="has-margin-bottom has-text-centered">
            <h3 class="has-text-blue-dark no-margin-bottom">Communication stream</h3>
            {{ numberOfMessages }} unread message(s)
          </div>
          <!-- <div>
            <a @click="markAllAsRead()">Mark all as read</a>
          </div>-->
          <hr />
          <transition-group name="inbox">
            <notification-meta-data
              v-for="notification in notifications"
              :key="notification.Id"
              :notificationDetail="notification"
              @click.native="showNotificationDetail(notification)"
              class="is-unselectable"
            />
          </transition-group>
          <transition
            name="fade"
            mode="out-in"
          >
            <div
              v-if="loadMoreNotifications"
              key="loader"
              class="has-text-centered has-margin-top"
            >
              <img
                src="@/assets/img/s2m-loader-small.gif"
                alt="loading..."
              />
            </div>
            <button
              v-if="showMoreButton && !loadMoreNotifications"
              class="button button-more has-margin-top"
              @click="getNotifications()"
            >More</button>
          </transition>
        </div>
      </transition>
      <div
        class="message-window-wrapper inbox-column has-background-white"
        :class="{
          'is-hidden-mobile': isMobile && !notificationDetail,
          'is-hidden-tablet': isMobile && notificationDetail,
        }"
      >
        <transition
          name="slide-fade"
          mode="out-in"
        >
          <div
            v-if="notificationDetail && !replyWindowIsActive"
            :key="notificationDetail.Id"
            class="column-action-message has-padding-left"
          >

            <!-- ACTION BAR -->
            <div class="action-row has-margin-bottom has-text-right">
              <a
                v-if="isMobile"
                @click="notificationDetail = null"
                class="button is-pulled-left has-margin-right"
              >
                <span class="icon">
                  <i class="fas fa-bars"></i>
                </span>
              </a>

              <!-- REVIEW ACTION -->
              <a
                v-if="notificationDetail.LinkTypeId === 'Review'"
                @click="goToReview(notificationDetail.LinkItemId)"
                class="button is-success has-margin-right"
              >{{ getButtonreplyInput(notificationDetail.LinkTypeId) }}</a>

              <!-- CHAT ACTION -->
              <a
                v-if="isChat(notificationDetail)"
                @click="goToChat(notificationDetail.ItemId)"
                class="button is-cyan has-margin-right"
              >
                <span>Go to chat</span>
                <span class="icon is-small">
                  <i class="fas fa-arrow-right"></i>
                </span>
              </a>

              <!-- QUESTION ACTION -->
              <a
                v-if="isQuestion(notificationDetail.LinkTypeId)"
                @click="
                  gotoQuestionDetail(
                    notificationDetail.LinkItemId > 0
                      ? notificationDetail.LinkItemId
                      : notificationDetail.ItemId
                  )
                "
                class="button is-cyan has-margin-right"
              >
                <span>View {{ !isMobile ? 'question' : '' }}</span>
                <span class="icon is-small">
                  <i class="fas fa-arrow-right"></i>
                </span>
              </a>

              <!-- DELETE ACTION -->
              <a
                class="button has-text-danger"
                @click="deleteNotification(notificationDetail.Id)"
              >
                <i class="far fa-trash-alt"></i>
              </a>
            </div>
            <!-- END ACTION BAR -->

            <div class="message-window">
              <notification-meta-data :notificationDetail="notificationDetail" />
              <hr />
              <div
                class="content"
                :inner-html.prop="processBody(notificationDetail)"
              ></div>
            </div>
          </div>

          <div
            v-if="!notificationDetail"
            key="notificationDetail"
            class="has-padding-left"
          >
            <div
              v-if="isDeleted"
              class="has-text-centered has-padding-top-x2"
            >
              <figure class="image is-128x128 is-inline-block">
                <img
                  src="@/assets/img/done.svg"
                  alt="done"
                  title="Your message is sent"
                />
              </figure>
              <div class="title is-3">Notification has been deleted.</div>
            </div>
            <div
              v-if="!isDeleted && !isMobile"
              class="notification has-icon is-info"
            >
              <span class="icon notification-icon fa-lg">
                <i class="fas fa-info-circle"></i>
              </span>
              <strong>No notification is selected</strong>
            </div>
          </div>
          <div
            v-if="replyWindowIsActive"
            class="reply-window has-padding-left"
          >
            <!-- REVIEW DETAIL ACTION -->
            <div class="action-row has-margin-bottom has-text-right">
              <a
                @click="replyWindowIsActive = false"
                class="button is-pulled-left has-margin-right"
              >
                <span class="icon">
                  <i class="fas fa-arrow-circle-left"></i>
                </span>
                <span>Back</span>
              </a>
              <transition
                name="fade"
                mode="out-in"
              >
                <section v-if="!isSent">
                  <a
                    @click="replyWindowIsActive = false"
                    class="button has-margin-right"
                  >Cancel</a>
                  <a
                    v-if="
                      notificationDetail.LinkTypeId === 'Review' &&
                        reviewState &&
                        !reviewState.isSuccessSaved
                    "
                    @click="saveReview"
                    class="button"
                    :disabled="
                      !reviewState.commentTextFilledIn &&
                        !reviewState.answerGiven
                    "
                    :class="{
                      'is-loading': reviewState.isSaving,
                      'is-success':
                        reviewState.commentTextFilledIn ||
                        reviewState.answerGiven,
                      'is-disabled':
                        !reviewState.commentTextFilledIn &&
                        !reviewState.answerGiven,
                    }"
                  >Post review</a>
                  <a
                    v-if="notificationDetail.LinkTypeId !== 'Review'"
                    @click="sendReply"
                    class="button"
                    :disabled="replyInput.length < 2 && isSsending"
                    :class="{
                      'is-loading': isSsending,
                      'is-success': replyInput.length > 1 && !isSsending,
                      'is-disabled': isSsending || replyInput.length < 2,
                    }"
                  >Send</a>
                </section>
              </transition>
            </div>

            <div class="message-window">
              <!-- GIVE A REVIEW -->
              <div v-if="notificationDetail.LinkTypeId === 'Review'">
                <div v-show="reviewState && !reviewState.isLoading">
                  <div v-if="reviewObj">
                    <h3 class="is-marginless">Review you experience at {{ reviewObj.LocationName }}</h3>
                    <h4>Your review will be displayed on the website</h4>
                    <hr />
                  </div>
                  <location-review-input
                    ref="reviewInput"
                    v-show="updateReviewObj.ProfileId === profile.Id"
                    :reviewId="notificationDetail.LinkItemId"
                    @updateReviewObj="updateReviewObj"
                    @updateReviewState="updateReviewState"
                  />
                </div>
                <div
                  v-if="reviewState && reviewState.isLoading"
                  class="has-text-centered has-padding"
                >
                  <img src="@/assets/img/s2m-loader-big.gif" />
                </div>
              </div>

              <!-- GIVE A REPLY -->
              <div v-if="notificationDetail.LinkTypeId !== 'Review'">
                <div class="media">
                  <div class="media-left">
                    <strong>Reply on:</strong>
                  </div>
                  <div class="media-content">
                    <span
                      v-if="notificationDetail.CreatedByName"
                      class="is-small"
                    >{{ notificationDetail.CreatedByName }}</span>:
                    <strong :class="{ 'has-text-grey': notificationDetail.IsRead }">{{ notificationDetail.Subject }}</strong>
                    <br />
                    <span class="is-small">
                      {{
                      notificationDetail.CreatedOn
                      | dateTimeStampToDate
                      | longDateFormat
                      }}
                      -
                      {{ notificationDetail.CreatedOn | timeFromDateTimeStamp }}
                    </span>
                  </div>
                </div>
                <hr />
                <transition
                  name="fade"
                  mode="out-in"
                >
                  <div
                    v-if="!isSent"
                    key="messageInput"
                    class="input-body-wrapper"
                  >
                    <textarea
                      class="textarea has-margin-bottom"
                      v-model="replyInput"
                    />

                    <p>&nbsp;</p>
                    <article class="message is-info">
                      <div class="message-body">
                        <notification-meta-data :notificationDetail="notificationDetail" />
                        <hr />
                        <div
                          class="content"
                          :inner-html.prop="notificationDetail.Body"
                        ></div>
                      </div>
                    </article>
                  </div>
                  <div
                    v-else
                    key="isSent"
                    class="has-text-centered"
                  >
                    <figure class="image is-128x128 is-inline-block">
                      <img
                        src="@/assets/img/done.svg"
                        alt="done"
                        title="Your message is sent"
                      />
                    </figure>
                    <div class="title is-3">Your message is sent.</div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </transition>
      </div>

    </div>
  </div>
</template>

<script>
import { EventBus } from '@/eventBus/event-bus'
import { mapGetters, mapMutations } from 'vuex'
import notificationMetaData from '@/components/partial/NotificationMetaData'
import LocationReviewInput from '@/components/partial/LocationReviewInput'
import notificationProvider from '../providers/notification'
import chatProvider from '@/providers/chat'
import commentProvider from '@/providers/comment'
import questionProvider from '@/providers/question'

export default {
  name: 'inbox',

  components: {
    'location-review-input': LocationReviewInput,
    'notification-meta-data': notificationMetaData
  },

  data() {
    return {
      showInbox: true,
      isDeleted: false,
      isLoading: true,
      isMobile: false,
      loadMoreNotifications: false,
      itemsPerPage: 50,
      notifications: [],
      notificationDetail: null,
      page: 1,
      showAll: false,
      replyWindowIsActive: false,
      replyInput: '',
      isSsending: false,
      isSent: false,
      typeIsReview: false,
      showMoreButton: false,
      reviewObj: null,
      reviewState: null
    }
  },

  computed: {
    ...mapGetters({
      passportState: 'getPassportState',
      profile: 'getProfile'
    }),

    numberOfMessages() {
      let totalNewMessages = 0

      if (this.notifications.length) {
        totalNewMessages += this.notifications.filter(n => n.IsRead === false)
          .length
      }
      return totalNewMessages
    }
  },

  watch: {
    replyWindowIsActive(val) {
      if (!val) {
        this.reset()
      }
    }
  },

  created() {
    let self = this

    // Get notifications
    this.getNotifications()

    // Listeners
    EventBus.$off('newNotificationMessage')
    EventBus.$on('newNotificationMessage', value => {
      self.notifications.unshift(value)
    })
  },

  mounted() {
    window.addEventListener('resize', this.resizeHandler)
    this.resizeHandler()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler)
    EventBus.$off('newNotificationMessage')
  },

  methods: {
    ...mapMutations('chatStore', ['setSelectedChat']),

    resizeHandler() {
      let self = this
      self.isMobile =
        self.$refs.inboxContainer.offsetWidth &&
        self.$refs.inboxContainer.offsetWidth < 700
    },

    processBody(notificationDetail) {
      if (
        (this.isQuestion(notificationDetail.LinkTypeId) && notificationDetail.TypeId === 'Question' && (notificationDetail.Subject.indexOf('replied') === -1 && notificationDetail.Subject.indexOf('beantwoord') === -1))
        || notificationDetail.LinkTypeId === 'Chat'
        || (notificationDetail.LinkTypeId === 'None' && notificationDetail.TypeId === 'Comment')
      ) {
        return this.$options.filters.nl2br(notificationDetail.Body)
      }

      let body = notificationDetail.Body
      if (this.isQuestion(notificationDetail.LinkTypeId) && (notificationDetail.Subject.indexOf('replied') !== -1 || notificationDetail.Subject.indexOf('beantwoord') !== -1)) {
        body = body.replace('##link##', '/questions/' + (notificationDetail.LinkItemId > 0
          ? notificationDetail.LinkItemId
          : notificationDetail.ItemId)
        )
      }
      return body.replace('##unsubscribe##', '')
    },

    /**
     * Delete notification
     */
    deleteNotification(notificationId) {
      let self = this

      notificationProvider.methods
        .deleteNotification(notificationId)
        .then(response => {
          let notifications = self.notifications
          self.reset()
          self.notificationDetail = null
          self.isDeleted = true

          let notificationIndex = notifications.findIndex(
            n => n.Id === notificationId
          )
          notifications.splice(notificationIndex, 1)
        })
    },

    isQuestion(linkTypeId) {
      return linkTypeId === 'Question'
        || linkTypeId === 32
        || linkTypeId === 33
        || linkTypeId === 34
    },

    isChat(item) {
      return item.LinkTypeId === 'Chat'
        || (item.LinkTypeId === 'None' && item.TypeId === 'Comment' && item.CreatedBy > 0)
        || item.TypeId === 'Chat'
    },

    /**
     * Get button reply text
     */
    getButtonreplyInput(linkTypeId) {
      let text = ''
      switch (linkTypeId) {
        case 5:
        case 'Review':
          text = 'Give review'
          break
        case 32:
        case 33:
        case 34:
        case 'Question':
          text = this.isMobile ? 'Give answer' : 'Give your answer'
          break
        default:
          text = 'Reply'
          break
      }
      return text
    },

    /**
     * Get notifications
     */
    getNotifications() {
      let self = this

      if (!self.loadMoreNotifications) {
        self.loadMoreNotifications = true

        notificationProvider.methods
          .getProfileNotifications(this.page, this.itemsPerPage, this.showAll)
          .then(response => {
            self.showMoreButton = Boolean(this.itemsPerPage <= response.data.length)
            self.notifications = self.notifications.concat(
              response.data
            )
          })
          .catch(e => { })
          .then(() => {
            self.loadMoreNotifications = false
            self.page++
          })
      }
    },

    /**
     * Reset variables after selecting new notification or on go back to detail view
     */
    reset() {
      this.isSent = false
      if (this.replyWindowIsActive) {
        this.notificationDetail = null
      }
      this.replyWindowIsActive = false
    },

    /**
     * Save review
     */
    saveReview() {
      this.$refs.reviewInput.saveReview()
    },

    /**
     * Show profile detail drawer
     */
    showProfileDetail(profileId) {
      this.$store.commit('setSelectedPublicProfile', {
        profileId: profileId,
        view: 'detail'
      })
    },

    /**
     * Show notification detail
     */
    showNotificationDetail(notification) {
      let self = this
      this.reset()

      notification.Body = notification.Body
        .replace('https://wallet.cyberdigma.nl', '')
        .replace('https://wallet-staging.cyberdigma.nl', '')

      this.notificationDetail = notification

      if (!notification.IsRead) {
        notificationProvider.methods
          .markNotificationAsRead(notification.Id)
          .then(response => {
            if (response.status === 200) {
              let notificationIndex = this.notifications.findIndex(
                n => n.Id === notification.Id
              )

              if (notificationIndex > -1) {
                let notifications = this.notifications
                notifications[notificationIndex] = Object.assign(notifications[notificationIndex], response.data)
                this.$store.commit('setNotifications', notifications)
              }

              self.passportState.NrOfUnreadNotifications--
              this.$store.commit('setPassportState', self.passportState)
            }
          })
      }
    },

    /**
     * Go to chat
     */
    goToChat(chatId) {
      chatProvider.methods.getChatById(chatId).then(response => {
        this.setSelectedChat(response.data)
        this.$router.push({ path: `chats` })
      })
    },

    /**
     * Go to reviews
     */
    goToReview(linkItemId) {
      this.$router.push({ name: 'Reviews', query: { cr: linkItemId } })
    },

    /**
     * Go to question
     */
    gotoQuestionDetail(ItemId) {
      this.$router.push({
        name: 'QuestionDetail',
        params: { questionId: ItemId }
      })
    },

    /**
     * Mark all notifications as read
     */
    markAllAsRead() {
      // API is er nog niet
    },

    /**
     * Show review window
     */
    showReplyWindow(linkTypeId) {
      this.replyWindowIsActive = true
      if (linkTypeId === 'Review') {
        // show review form
        this.typeIsReview = true
      } else {
        // show reply form
        this.typeIsReview = false
      }
    },

    /**
     * Send reply
     */
    sendReply() {
      let self = this

      if (!this.isSsending) {
        this.isSsending = true

        switch (this.notificationDetail.TypeId) {
          // Send message
          case 21:
            this.sendMessage()
            break
          // Question answer
          case 32:
            break
          // Reply to comment
          case 6:
          case 23:
          case 34:
            this.replyToComment()
            break
          // Add reply
          case 33:
            this.addComment()
            break
        }
      }
    },

    /**
     * Add comment
     */
    addComment() {
      commentProvider.methods
        .addComment(
          'answer',
          this.notificationDetail.ItemId,
          this.$options.filters.stripHtml(this.replyInput)
        )
        .then(response => {
          this.replyInput = ''
          this.showReplyInput = false
        })
        .catch(e => { })
        .then(() => {
          this.showIsSentMessage()
        })
    },

    /**
     * Reply to comment
     */
    replyToComment() {
      commentProvider.methods
        .replyToComment(
          this.notificationDetail.ItemId,
          this.$options.filters.stripHtml(this.replyInput)
        )
        .then(response => {
          this.replyInput = ''
          this.showReplyInput = false
        })
        .catch(e => { })
        .then(() => {
          this.showIsSentMessage()
        })
    },

    /**
     * Send message
     */
    sendMessage() {
      chatProvider.methods
        .sendMessage(
          this.notificationDetail.ItemId,
          this.$options.filters.stripHtml(this.replyInput)
        )
        .then(response => {
          this.replyInput = ''
          this.showReplyInput = false
          // this.notificationDetail = null

          this.updateChat(response.data)
        })
        .catch(e => { })
        .then(() => {
          this.showIsSentMessage()
        })
    },

    /**
     * Show response successful has been sent message
     */
    showIsSentMessage() {
      this.isSsending = false
      this.isSent = true
    },

    /**
     * Update review object
     */
    updateReviewObj(val) {
      this.updateReviewObj = val
    },

    /**
     * Update review state
     */
    updateReviewState(val) {
      this.reviewState = val
    }
  }
}
</script>

<style lang="scss" scoped>
.inbox-enter-active,
.inbox-leave-active {
  transition: opacity 0.3s, transform 0.3s;
  transform-origin: center;
}
.inbox-enter, .inbox-leave-to /* .list-leave-active for <2.1.8 */ {
  opacity: 0;
  transform: scale(0.5);
}

.inbox-wrapper {
  align-items: stretch;
  display: flex;
  position: relative;
  justify-content: flex-start;
  height: 78vh;
  width: 100%;

  .list-wrapper {
    border-right: 1px solid $grey-light;
    height: 100%;
    overflow: auto;
    width: 30%;

    &.is-mobile {
      border-right: 0px solid $grey-light;
      width: 100%;
    }

    .media {
      cursor: pointer;
    }
    .button-more {
      width: 100%;
    }
  }

  .message-window-wrapper {
    height: 100%;
    &.is-hidden-tablet {
      position: absolute;
      top: $gap;
      right: $gap;
      bottom: $gap;
      left: 0;
      width: auto;
      height: auto;
    }

    width: 70%;
    .column-action-message,
    .reply-window {
      align-items: flex-start;
      align-items: stretch;
      display: flex;
      flex-direction: column;
      height: 100%;

      .action-row,
      .message-window {
        align-self: stretch;
      }

      .action-row {
        flex-grow: 0;
      }

      .message-window {
        height: 100%;
        overflow: auto;
        padding-right: 5px;
      }
    }

    .reply-window {
      height: 100%;
    }
  }
}
</style>
