<template>
  <div
    v-if="notificationDetail"
    class="media notification-meta-data"
  >
    <div class="media-left">
      <figure class="image is-48x48">
        <i
          class="font-icon"
          :class="getIconClass(notificationDetail)"
        ></i>
        <img
          v-if="!notificationDetail.CreatedBy"
          src="@/assets/img/spacer1_1.gif"
          style="background-size: contain;"
          :style="{backgroundImage: `url(${require('@/assets/img/logo.svg')})`}"
          class="is-round has-shadow has-background-grey-light"
        >
        <img
          v-else
          src="@/assets/img/spacer1_1.gif"
          class="is-round has-shadow has-background-grey-light"
          :style="{backgroundImage: `url(${$options.filters.getProfileImage(notificationDetail.CreatedByImage, notificationDetail.CreatedBy, 84)})`}"
        >
      </figure>
    </div>
    <div class="media-content">
      <div
        class="content no-margin-bottom"
        :class="{'has-text-grey' : notificationDetail.IsRead}"
      >
        <p>
          <span v-if="notificationDetail.CreatedByName">
            {{notificationDetail.CreatedByName}}<br />
          </span>
          <strong :class="{'has-text-grey' : notificationDetail.IsRead}">{{notificationDetail.Subject}}</strong><br />
          <span class="is-small">{{notificationDetail.CreatedOn | dateTimeStampToDate | longDateFormat}} - {{notificationDetail.CreatedOn | timeFromDateTimeStamp}}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    notificationDetail: null
  },

  methods: {
    /**
     * Get icon bases on notification type
     */
    getIconClass(item) {
      // Information icon; S2M messages
      if (item.CreatedBy === '' && (
        item.LinkTypeId === 'Review'
        || item.LinkTypeId === 'Reservation'
      )
      ) {
        return 's2m-icon-information-circle has-text-primary-dark'
      }

      // Peace icon; Question, answers
      if (item.LinkTypeId === 'Question' || item.LinkTypeId === 32 || item.LinkTypeId === 33 || item.LinkTypeId === 34) {
        return 's2m-icon-icons-notifications-01 has-text-blue-dark'
      }

      // Message icon; Meet, chat, comment
      if (item.LinkTypeId === 'None'
        || item.LinkTypeId === 'Chat'
        || item.LinkTypeId === 'Review'
      ) {
        return 's2m-icon-icons-notifications-02 has-text-blue-dark'
      }

      return ''

      // let cls = ''
      // switch (item.linkTypeId) {
      //   // Information icon; S2M messages
      //   case 5:
      //   case 35:
      //   case 'Review':
      //   case 'Reservation':
      //   case 'newReview': // 5
      //   case 'ReservationReminder': // 35
      //     cls = 's2m-icon-information-circle has-text-primary-dark'
      //     break
      //   // Peace icon; Question, answers
      //   case 32:
      //   case 33:
      //   case 34:
      //   case 'Question':
      //   case 'NewQuestion': // 32
      //   case 'NewQuestionAnswer': // 33
      //   case 'NewAnswerComment': // 34
      //     cls = 's2m-icon-icons-notifications-01 has-text-blue-dark'
      //     break
      //   // Message icon; Meet, chat, comment
      //   case 21:
      //   case 23:
      //   case 'None':
      //   case 'Chat':
      //   case 'NewChatMessage': //21
      //   case 'NewReviewComment': // 23
      //     cls = 's2m-icon-icons-notifications-02 has-text-blue-dark'
      //     break
      //   default:
      //     break
      // }
      // return cls
    },

    /**
     * Check if notification is system icon
     */
    isSystemNotification(item) {
      if (item.CreatedBy === '' && (
        item.LinkTypeId === 'Review'
        || item.LinkTypeId === 'Reservation'
      )
      ) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.notification-meta-data {
  .image {
    position: relative;
    .font-icon {
      position: absolute;
      right: -8px;
      top: -4px;
    }
  }
}
</style>
